<template>
  <b-container>
    <b-row
      class="my-4"
    >
      <b-col>
        <b-alert
          :show="mqttReconnecting"
          variant="danger"
        >
          Live update offline, reconnecting
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
          v-if="!$route.query.popup"
          class="float-right open-as-popup"
        >
          <a
            href="#"
            @click.prevent="openAsPopup"
          >
            Open as popup
            <i class="material-icons">launch</i>
          </a>
        </div>
        <div>
          <b-form-select
            v-model="requestFilter"
            :options="requestFilterOptions"
            size="sm"
            :disabled="isASLAgent"
          />
        </div>
        <b-table
          id="request-table"
          bordered
          empty-text="No Explorers are waiting for an Agent. Yay!"
          :fields="requestFields"
          :items="filteredRequests"
          show-empty
          sort-by="waitTime"
        >
          <template
            #cell(user)="data"
          >
            <div>
              <a
                :href="getUserMgmtUrl(data.value.userId)"
                target="_blank"
              >
                {{ data.value.fullName }}
              </a>
              <img
                v-if="countryListWithIcons.includes(data.value.country)"
                :src="require(`../../assets/flags/${data.value.country.toLowerCase()}.svg`)"
                :alt="`${data.value.country} flag icon`"
                :style="{ width: '18px', height: '18px', marginRight: '2px' }"
              >
              <b-badge
                v-if="typeof data.value.callCount === 'number'"
                v-tooltip="`${data.value.callCount} call${data.value.callCount !== 1 ? 's' : ''}`"
                class="call-count-badge"
              >
                {{ data.value.callCount }}
              </b-badge>
              <img
                v-if="data.value.language === 'French' || data.value.language === 'Spanish'"
                v-tooltip="`${data.value.language} ${data.value.englishSecondLanguage ? 'preferred' : 'required'}`"
                class="language-icon"
                :src="require(`../../assets/language-${data.value.language.toLowerCase()}.svg`)"
              >
              <i
                v-if="data.value.doNotRecord"
                v-tooltip="'Do not record'"
                class="material-icons"
              >
                motion_photos_off
              </i>
            </div>
          </template>
          <template
            #cell(service)="data"
          >
            <div v-if="data.value.serviceId">
              <router-link
                :to="{ name: 'report', query: { serviceID: data.value.serviceId } }"
                target="_blank"
              >
                {{ data.value.serviceId }}
              </router-link><i
                v-if="data.value.isReconnect"
                v-tooltip="'Reconnect'"
                class="material-icons"
              >
                replay
              </i>
              <img
                v-if="data.value.isTransfer"
                v-tooltip="'Transfer'"
                class="skill-icon"
                :src="require('../../assets/ic_transfer.svg')"
              >
            </div>
            <div v-else-if="data.value.taskType === 'IMAGE_CHAT_VALIDATION'">
              <router-link
                :to="{ name: 'chatReport', query: { chatId: data.value.taskDetails.chatId } }"
                target="_blank"
              >
                {{ data.value.taskDetails.chatId }}
                <i class="material-icons">forum</i>
              </router-link>
            </div>
          </template>
          <template
            #cell(waitTime)="data"
          >
            <div v-tooltip="'Requested at ' + data.value.requestedAt.toLocaleString()">
              <!-- If the request has been canceled, stop incrementing the wait time -->
              {{ formatDuration((data.value.canceledAt || new Date()) - data.value.requestedAt) }}
            </div>
          </template>
        </b-table>
        <div
          v-if="isCallThrottlingActive && requestFilter !== 'ASL'"
          id="throttling-alert"
        >
          CALL THROTTLING ACTIVE
        </div>
        <b-table
          id="status-table"
          hover
          condensed
          bordered
          :items="filteredItems"
          :fields="visibleFields"
          :filter="filter"
          :filter-included-fields="filterOn"
          :busy.sync="isTableLoading"
          stacked="false"
          show-empty
          sort-by="status"
          :sort-compare="sortCompare"
          no-sort-reset
        >
          <template
            #thead-top="data"
          >
            <b-tr>
              <b-th :colspan="data.columns">
                <div
                  id="agent-count"
                  class="float-left"
                >
                  Agents: {{ filteredItems.length }}
                </div>
                <div class="float-left">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    size="sm"
                    placeholder="Type to filter"
                  />
                </div>
                <div class="float-right">
                  <vue-multi-select
                    v-model="showColumns"
                    btn-class="columns-dropdown"
                    :btn-label="showColumnsBtnLabel"
                    :options="showColumnsOptions"
                    popover-class="columns-popover"
                    position="bottom-right"
                    :select-options="showColumnsSelectOptions"
                  />
                </div>
              </b-th>
            </b-tr>
          </template>
          <template
            #cell(status)="data"
          >
            <div v-if="data.value.status === 'in session' && data.item.serviceId">
              <div>
                {{ data.value.fullName }}
                <img
                  v-if="data.value.language === 'French' || data.value.language === 'Spanish'"
                  v-tooltip="`${data.value.language} ${data.value.englishSecondLanguage ? 'preferred' : 'required'}`"
                  class="skill-icon"
                  :src="require(`../../assets/language-${data.value.language.toLowerCase()}.svg`)"
                >
              </div>
              <div>{{ data.value.accessOffer }}</div>
              <div>
                <span>
                  <router-link
                    :to="{ name: 'report', query: { serviceID: data.item.serviceId } }"
                    target="_blank"
                  >
                    {{ data.item.serviceId }}
                  </router-link>
                </span>
                <span v-tooltip="'Started at ' + data.value.startedAt.toLocaleString()">
                  ({{ formatDuration(new Date() - data.value.startedAt) }})
                </span>
              </div>
            </div>
            <div v-else-if="data.value.status === 'in session' && data.value.taskType === 'IMAGE_CHAT_VALIDATION'">
              <div>
                <router-link
                  :to="{ name: 'chatReport', query: { chatId: data.value.taskDetails.chatId } }"
                  target="_blank"
                >
                  {{ data.value.taskDetails.chatId }}
                  <i class="material-icons">forum</i>
                </router-link>
              </div>
              <div v-tooltip="'Started at ' + data.value.startedAt.toLocaleString()">
                {{ formatDuration(new Date() - data.value.startedAt) }}
              </div>
            </div>
            <div v-else>
              <div>
                {{ data.value.status }}
              </div>
              <div v-tooltip="'Started at ' + data.value.startedAt.toLocaleString()">
                {{ formatDuration(new Date() - data.value.startedAt) }}
              </div>
            </div>
          </template>
          <template
            #cell(name)="data"
          >
            <div>
              {{ data.value.fullName }}
              <img
                v-if="data.value.skills.includes('DialedIn')"
                class="skill-icon"
                :src="require('../../assets/DialedIn.png')"
              >
              <img
                v-if="data.value.skills.includes('French')"
                class="skill-icon"
                :src="require('../../assets/language-french.svg')"
              >
              <img
                v-if="data.value.skills.includes('Spanish')"
                class="skill-icon"
                :src="require('../../assets/language-spanish.svg')"
              >
            </div>
            <div>
              <a
                :href="getUserMgmtUrl(data.value.userId)"
                target="_blank"
              >
                {{ data.value.userId }}
              </a>
            </div>
          </template>
          <template
            #cell(lastSession)="data"
          >
            <div>
              <span v-if="data.value.serviceId">
                <router-link
                  :to="{ name: 'report', query: { serviceID: data.value.serviceId } }"
                  target="_blank"
                >
                  {{ data.value.serviceId }}
                </router-link>
              </span>
              <span v-else-if="data.value.taskId && data.value.taskType === 'IMAGE_CHAT_VALIDATION'">
                <router-link
                  :to="{ name: 'chatReport', query: { chatId: data.value.taskDetails.chatId } }"
                  target="_blank"
                >
                  {{ data.value.taskDetails.chatId }}
                  <i class="material-icons">forum</i>
                </router-link>
              </span>
              <span v-if="data.value.duration">
                ({{ data.value.duration }})
              </span>
            </div>
            <div
              v-if="data.value.endTimestamp"
              v-tooltip="'Ended at ' + data.value.endTimestamp.toLocaleString()"
            >
              {{ formatTimeAgo(data.value.endTimestamp) }}
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import * as MQTT from 'async-mqtt';
import vueMultiSelect from 'vue-multi-select';
import API from '@/api';

export default {
  name: 'AgentActivity',
  components: {
    vueMultiSelect,
  },
  data() {
    return {
      pageName: 'agentActivity',
      fields: [
        {
          key: 'name',
          label: 'Agent',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
          class: ['text-capitalize'],
        },
        {
          key: 'lastSession',
          sortable: true,
          label: 'Last Session',
        },
        {
          key: 'skills',
          label: 'Skills',
        },
        {
          key: 'versionNumber',
          sortable: true,
          label: 'Version Number',
        },
      ],
      items: [],
      filter: null,
      filterOn: ['name'],
      isTableLoading: true,
      mqttReconnecting: false,
      firstConnectAlreadyHappened: false,
      mqttClient: null,
      refreshTimer: null,
      isCallThrottlingActive: false,
      throttlingInterval: null,
      countryListWithIcons: ['USA', 'CAN', 'AUS', 'GBR', 'IRL', 'NZL', 'IND', 'NLD', 'PRI', 'PAK', 'MEX', 'ESP'],
      showColumns: [
        { name: 'Agent' },
        { name: 'Status' },
        { name: 'Last Session' },
      ],
      showColumnsBtnLabel: () => '<i class="material-icons">settings</i>',
      showColumnsOptions: {
        multi: true,
      },
      showColumnsSelectOptions: [
        { name: 'Agent', disabled: true },
        { name: 'Status', disabled: true },
        { name: 'Last Session' },
        { name: 'Skills' },
        { name: 'Version Number' },
      ],
      requests: [],
      requestFields: [
        {
          key: 'user',
          label: 'Explorer',
        },
        {
          key: 'service',
          label: 'Session',
        },
        {
          key: 'waitTime',
          label: 'Wait Time',
        },
        {
          key: 'accessOffer',
          label: 'Access Offer',
        },
      ],
      requestFilter: 'All', // New property for request filtering
      requestFilterOptions: [
        { text: 'All SERVICES', value: 'All' },
        { text: 'ASL SERVICES', value: 'ASL' },
        { text: 'BVI SERVICES', value: 'BVI' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'platformToken',
      'role',
      'userId',
      'observerAgentsIds',
      'isASLAgent',
    ]),
    visibleFields() {
      return this.fields.filter((field) => this.showColumns.find((value) => value.name === field.label));
    },
    isDialedInManager() {
      return this.role.includes('DI_MANAGER');
    },
    filteredRequests() {
      if (this.requestFilter === 'All') {
        return this.requests;
      } else if (this.requestFilter === 'ASL') {
        return this.requests.filter((request) => request.requestSource === 'explorer-ui-asl');
      } else if (this.requestFilter === 'BVI') {
        return this.requests.filter((request) => request.requestSource === 'explorer-ui');
      }
      return this.requests;
    },
    filteredItems() {
      if (this.requestFilter === 'All') {
        return this.items;
      } else if (this.requestFilter === 'ASL') {
        return this.items.filter((item) => item.name.skills && item.name.skills.includes('ASL'));
      } else if (this.requestFilter === 'BVI') {
        return this.items.filter((item) => !item.name.skills || !item.name.skills.includes('ASL'));
      }
      return this.items;
    },
  },
  async mounted() {
    if (this.isASLAgent) {
      this.requestFilter = 'ASL';
    }
    this.subscribeToMqtt()
      .then(this.getCurrentActivityFromPlatform)
      .then(() => {
        this.$nextTick(() => {
          this.isTableLoading = false;
        });
      });
    this.refreshTimer = setInterval(() => {
      this.$root.$emit('bv::refresh::table', 'status-table');
      this.removeCanceledRequests();
      this.$root.$emit('bv::refresh::table', 'request-table');
    }, 1000);
    // Check if call throttling is active when the component is mounted
    this.updateIsCallThrottlingActive();
    // Set an interval to check if call throttling is active
    this.throttlingInterval = setInterval(() => this.updateIsCallThrottlingActive(), 15000);
  },
  beforeDestroy() {
    if (this.mqttClient != null) {
      this.mqttClient.end();
      this.mqttClient = null;
    }
    if (this.refreshTimer != null) {
      clearInterval(this.refreshTimer);
    }
    // Clear the throttling interval when the component is destroyed
    if (this.throttlingInterval != null) {
      clearInterval(this.throttlingInterval);
    }
  },
  methods: {
    async subscribeToMqtt() {
      if (this.mqttClient != null) {
        console.info('mqtt client already initialized, ignoring resub');
        return;
      }

      const options = {
        clean: true,
        keepalive: 30,
        username: `Token-${this.platformToken}`,
        password: 'internal-agent-activity',
        clientId: `internal-agent-activity-${this.userId}-${Math.round(Math.random() * 100000).toString()}`,
        reconnectPeriod: 2500, // ms
      };
      MQTT.connectAsync(process.env.VUE_APP_MQTT_URL, options).then((mqttClient) => {
        this.mqttClient = mqttClient;
        this.firstConnectAlreadyHappened = true;
        mqttClient.on('close', (e) => {
          console.error('mqtt close', e);
          this.mqttReconnecting = true;
        });
        mqttClient.on('connect', (e) => {
          console.info('mqtt connected', e);
          if (this.firstConnectAlreadyHappened) {
            this.mqttReconnecting = false;
            this.isTableLoading = true;
            this.getCurrentActivityFromPlatform();
            this.isTableLoading = false;
          }
        });
        mqttClient.on('error', (e) => {
          console.error('mqtt error', e);
        });
        mqttClient.on('offline', (e) => {
          console.warn('mqtt offline', e);
        });
        mqttClient.on('reconnect', (e) => {
          console.info('mqtt reconnecting', e);
        });
        mqttClient.on('message', (topic, message) => {
          let payload;
          try {
            payload = JSON.parse(message.toString());
            console.log(payload);
          } catch (err) {
            console.log('MQTT error parsing, MQTT data not JSON');
          }

          this.upsertDashState(payload);
          this.$root.$emit('bv::refresh::table', 'status-table');
        });

        mqttClient.subscribe(`${process.env.VUE_APP_ENV_NAME}/internal/agent/activity`);
      });
    },
    async getCurrentActivityFromPlatform() {
      try {
        const response = await API.getInitialAgentActivity();
        // Separate observers from non-observers in session to avoid showing observers in the activity page
        const { observersInSession, nonObserversInSession } = response.payload.reduce((accData, activityData) => {
          if (this.observerAgentsIds.includes(activityData.userId.toString()) && activityData.status === 'in session') {
            accData.observersInSession.push(activityData);
          } else {
            accData.nonObserversInSession.push(activityData);
          }
          return accData;
        }, { observersInSession: [], nonObserversInSession: [] });
        // filtering logic need non observers to be processed first
        nonObserversInSession.forEach((dashState) => {
          this.upsertDashState(dashState);
        });
        observersInSession.forEach((dashState) => {
          this.upsertDashState(dashState);
        });
      } catch (error) {
        console.error(error);
      }
    },
    upsertDashState(dashState) {
      if (dashState.component !== 'dashboard') {
        if (dashState.action) {
          this.upsertRequest(dashState);
        } else if (dashState.taskId) {
          this.upsertTaskRequest(dashState);
        } else {
          console.debug('ignoring non-dashboard component status change');
        }
        return;
      }
      const indexOfCurrentState = this.items.findIndex((element) => element.name.userId === dashState.userId);

      // Don't show observer agents in activity page if is Away or in a session with other agent (assumes is observing)
      const isSessionInTheList = this.items.some((element) => element.serviceId === dashState.serviceId);
      const isObserver = this.observerAgentsIds.includes(dashState.userId.toString());
      const isNotAvailableOrWFState = dashState.status !== 'available' && dashState.status !== 'writing feedback';
      const isAwayOrInList = dashState.status === 'away' || isSessionInTheList;
      if (isObserver && isNotAvailableOrWFState && isAwayOrInList) {
        if (indexOfCurrentState !== -1) {
          this.items.splice(indexOfCurrentState, 1);
        }
        return;
      }

      // HACK: Do not show Aira Agents to DialedIn Managers.
      if (this.isDialedInManager
          && !(dashState.routingGroups || []).includes('DialedIn')) {
        console.debug('ignoring Aira Agent status change');
        return;
      }

      if (dashState.status === 'offline') {
        if (indexOfCurrentState === -1) {
          console.debug('obj not found, ignoring offline delete');
        } else {
          this.items.splice(indexOfCurrentState, 1);
        }
      } else {
        let obj;
        if (indexOfCurrentState === -1) {
          console.debug('obj not found, creating new obj');
          obj = {
            _cellVariants: {},
            name: {},
            status: {},
            lastSession: {},
          };
        } else {
          console.debug(`obj found at index ${indexOfCurrentState}`);
          obj = this.items[indexOfCurrentState];
        }

        obj.serviceId = dashState.serviceId;
        if (dashState.lastServiceRequest && dashState.lastServiceRequest.serviceId != null && !isObserver) { // not show observers last service
          if (dashState.lastServiceRequest.endTimestamp) {
            obj.lastSession.endTimestamp = new Date(dashState.lastServiceRequest.endTimestamp);
          }
          obj.lastSession.serviceId = dashState.lastServiceRequest.serviceId;
          if (dashState.lastServiceRequest.duration) {
            obj.lastSession.duration = this.formatDuration(dashState.lastServiceRequest.duration * 1000);
          }
        }
        if (dashState.lastTask) {
          // Use the last task instead of the last service request if it's more recent.
          const { taskId, taskType, taskDetails, updatedAt } = dashState.lastTask;
          const endTimestamp = new Date(updatedAt);
          if (!obj.lastSession.endTimestamp || obj.lastSession.endTimestamp < endTimestamp) {
            // TODO: Update this when we have task durations.
            obj.lastSession = { endTimestamp, taskId, taskType, taskDetails };
          }
        }
        if (dashState.lastName) {
          obj.name.fullName = `${dashState.firstName} ${dashState.lastName}`;
        } else {
          obj.name.fullName = dashState.firstName;
        }
        obj.id = dashState.id;
        obj.name.userId = dashState.userId;
        obj.versionNumber = dashState.version;
        obj.status.status = dashState.status;
        obj.status.startedAt = new Date(dashState.startedAt);
        if (dashState.status === 'in session') {
          if (dashState.serviceRequest) {
            const { serviceRequest } = dashState;
            obj.status.fullName = `${serviceRequest.firstName} ${serviceRequest.lastName}`;

            const { preferredLang } = serviceRequest;
            if (preferredLang.includes('French')) {
              obj.status.language = 'French';
              obj.status.englishSecondLanguage = preferredLang.includes('English');
            } else if (preferredLang.includes('Spanish')) {
              obj.status.language = 'Spanish';
              obj.status.englishSecondLanguage = preferredLang.includes('English');
            } else {
              obj.status.language = 'English';
            }

            const { accessOffer } = serviceRequest;
            if (accessOffer) {
              obj.status.accessOffer = accessOffer.access.name;
              if (obj.status.accessOffer === 'Employer Access') {
                // Append the employer name.
                obj.status.accessOffer += ` - ${accessOffer.access.account.name}`;
              } else if (obj.status.accessOffer === 'Free Daily Calls') {
                obj.status.accessOffer += ` - ${accessOffer.access.key === 'FMF_GUEST' ? 'Guest' : 'Subscriber'}`;
              }
            } else {
              obj.status.accessOffer = '';
            }
          } else if (dashState.task) {
            const { taskId, taskType, taskDetails } = dashState.task;
            obj.status.taskId = taskId;
            obj.status.taskType = taskType;
            obj.status.taskDetails = taskDetails;

            // HACK: If the Agent is working on a task, remove the task from the requests. When we start receiving task
            // assignment events, this should be changed.
            const requestIndex = this.requests.findIndex((r) => r.service.taskId === taskId);
            if (requestIndex !== -1) {
              this.requests.splice(requestIndex, 1);
            }
          }
        }

        if (dashState.routingGroups) {
          obj.skills = dashState.routingGroups.filter((skill) => skill !== 'Aira Explorer').sort().join(', ');
          obj.name.skills = dashState.routingGroups;
          if (dashState.routingGroups.includes('DialedIn')) {
            obj._cellVariants.name = 'secondary'; // eslint-disable-line no-underscore-dangle
          }
        } else {
          obj.name.skills = [];
        }

        if (dashState.status === 'available') {
          // eslint-disable-next-line no-underscore-dangle
          obj._cellVariants.status = 'success';
        } else if (dashState.status === 'away') {
          // eslint-disable-next-line no-underscore-dangle
          obj._cellVariants.status = 'danger';
        } else if (dashState.status === 'in session') {
          // eslint-disable-next-line no-underscore-dangle
          obj._cellVariants.status = 'warning';
        } else if (dashState.status === 'writing feedback') {
          // eslint-disable-next-line no-underscore-dangle
          obj._cellVariants.status = 'info';
        } else {
          // eslint-disable-next-line no-underscore-dangle
          obj._cellVariants.status = 'primary';
        }

        if (indexOfCurrentState === -1) {
          console.debug('pushing new object', obj);
          this.items.push(obj);
        } else {
          console.debug(`updating object ${indexOfCurrentState}`, obj);
          this.items[indexOfCurrentState] = obj;
          this.$root.$emit('bv::refresh::table', 'status-table');
        }
      }
    },
    // Convert seconds to HH:MM:SS (https://stackoverflow.com/a/1322771)
    formatDuration(ms) {
      let duration = new Date(ms).toISOString().substr(11, 8);
      if (duration.startsWith('00:')) {
        duration = duration.slice(3);
      }
      return duration;
    },
    // Formats the date as XhYm ago
    formatTimeAgo(date) {
      const seconds = (Date.now() - date.getTime()) / 1000;
      if (seconds < 60) {
        return 'just now';
      } else if (seconds < 3600) {
        return `${Math.floor(seconds / 60)}m ago`;
      } else {
        return `${Math.floor(seconds / 3600)}h${Math.floor((seconds % 3600) / 60)}m ago`;
      }
    },
    // https://bootstrap-vue.org/docs/components/table#custom-sort-compare-routine
    sortCompare(aRow, bRow, key) {
      const a = aRow[key];
      const b = bRow[key];

      switch (key) {
        case 'name':
          // Sort by full name
          return a.fullName.localeCompare(b.fullName);
        case 'status':
          // Sort by status, and break ties with startedAt (earliest to latest)
          if (a.status === b.status && a.startedAt instanceof Date && b.startedAt instanceof Date) {
            return a.startedAt - b.startedAt;
          }

          // Quick hack to sort "writing feedback" after "available" but before the other statuses
          const aStatus = a.status === 'writing feedback' ? 'available2' : a.status; // eslint-disable-line no-case-declarations
          const bStatus = b.status === 'writing feedback' ? 'available2' : b.status; // eslint-disable-line no-case-declarations
          return aStatus.localeCompare(bStatus);
        case 'lastSession':
          // Sort by serviceId, with empty values last
          return (typeof a.serviceId === 'number' ? a.serviceId : Number.MAX_SAFE_INTEGER) - (typeof b.serviceId === 'number' ? b.serviceId : Number.MAX_SAFE_INTEGER);
        case 'versionNumber':
          // https://stackoverflow.com/a/65687141
          return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
        default:
          // Fall back to the built-in sort-compare routine
          return null;
      }
    },
    openAsPopup() {
      const url = new URL(window.location.href);
      url.searchParams.set('popup', '1');

      const popup = window.open(url.href, 'AgentActivityPopup',
        'width=320,height=800,location=1,resizable=1,scrollbars=1');
      popup.focus();
    },
    upsertRequest(request) {
      const doNotRecord = request.accessOffer
          && request.accessOffer.access
          && request.accessOffer.access.account
          && !request.accessOffer.access.account.allowRecording;
      // HACK: Do not show do-not-record calls to DialedIn Managers.
      if (this.isDialedInManager && doNotRecord) {
        console.debug('ignoring do-not-record call');
        return;
      }

      const requestIndex = this.requests.findIndex((r) => r.service.serviceId === request.serviceId);

      if (request.action === 'REQUEST' || request.action === 'TRANSFER_REQUESTED') {
        const obj = {
          receivedAt: new Date(),
          requestSource: request.requestSource,
          service: {
            isReconnect: false,
            isTransfer: request.action === 'TRANSFER_REQUESTED',
            serviceId: request.serviceId,
          },
          user: {
            callCount: request.callCount,
            doNotRecord,
            englishSecondLanguage: false,
            fullName: `${request.firstName} ${request.lastName}`,
            language: 'English',
            userId: request.userId,
            country: request.country,
          },
          waitTime: {
            // HACK: We don't yet have a transfer timestamp, so assume it's now.
            requestedAt: request.action === 'TRANSFER_REQUESTED' ? new Date() : new Date(request.requestTimestamp),
          },
        };

        if (request.accessOffer && request.accessOffer.access) {
          obj.accessOffer = request.accessOffer.access.name;
          if (obj.accessOffer === 'Employer Access') {
            // Append the employer name
            obj.accessOffer += ` - ${request.accessOffer.access.account.name}`;
          } else if (obj.accessOffer === 'Free Daily Calls') {
            obj.accessOffer += ` - ${request.accessOffer.access.key === 'FMF_GUEST' ? 'Guest' : 'Subscriber'}`;
          }
        }

        if (request.assignmentRules && request.assignmentRules.length && request.assignmentRules[0].name === 'AGENT_RECONNECT_ASSIGNMENT') {
          obj.service.isReconnect = true;
        }

        if (request.preferredLang && request.preferredLang.includes('French')) {
          obj.user.language = 'French';
          obj.user.englishSecondLanguage = request.preferredLang.includes('English');
        } else if (request.preferredLang && request.preferredLang.includes('Spanish')) {
          obj.user.language = 'Spanish';
          obj.user.englishSecondLanguage = request.preferredLang.includes('English');
        }

        if (requestIndex === -1) {
          this.requests.push(obj);
        } else if (request.action !== 'TRANSFER_REQUESTED') {
          // Update the existing request (e.g. a reconnect gets skipped and broadcast to all)
          this.requests[requestIndex] = obj;
          this.$root.$emit('bv::refresh::table', 'request-table');
        }
      } else if (request.action === 'ASSIGNED' || request.action === 'STARTED') {
        // The request has been started, so remove it
        if (requestIndex !== -1) {
          this.requests.splice(requestIndex, 1);
        }
      } else if (requestIndex !== -1) {
        // The request has been canceled, so flag it (it will eventually be removed by removeCanceledRequests)
        this.requests[requestIndex]._cellVariants = { service: 'danger' }; // eslint-disable-line no-underscore-dangle
        this.requests[requestIndex].waitTime.canceledAt = new Date();
      }
    },
    upsertTaskRequest(request) {
      const requestIndex = this.requests.findIndex((r) => r.service.taskId === request.taskId);

      if (request.state === 'PENDING') {
        // We need to use the same structure used by upsertRequest.
        const obj = {
          receivedAt: new Date(),
          service: {
            taskId: request.taskId,
            taskType: request.type,
            taskDetails: request.taskDetails,
          },
          user: {
            fullName: request.explorerDetails.name,
            userId: request.explorerDetails.id,
          },
          waitTime: {
            requestedAt: new Date(request.createdAt),
          },
        };

        if (requestIndex === -1) {
          this.requests.push(obj);
        }
      } else if (requestIndex !== -1) {
        // The request is no longer pending, so remove it.
        this.requests.splice(requestIndex, 1);
      }
    },
    getUserMgmtUrl(userId) {
      return `${process.env.VUE_APP_MGMT_URL}/users/${userId}`;
    },
    removeCanceledRequests() {
      // Remove requests that were canceled 5+ seconds ago and requests that were received 30+ seconds ago. (Requests
      // are re-broadcast every 20 seconds. If we have one from 30+ seconds ago, it is likely stale; maybe we lost our
      // network connection and missed the STARTED message.)
      for (let i = 0; i < this.requests.length; i += 1) {
        const request = this.requests[i];
        if (request.service.taskId) {
          // This does not apply to tasks right now, since they can't be canceled and are not re-broadcast.
          continue; // eslint-disable-line no-continue
        }

        if ((request.waitTime.canceledAt && new Date() - request.waitTime.canceledAt >= 5000)
            || new Date() - request.receivedAt >= 30000) {
          this.requests.splice(i, 1);
          i -= 1;
        }
      }
    },
    updateIsCallThrottlingActive() {
      API.getCallThrottlingStatus().then((response) => {
        this.isCallThrottlingActive = response.value;
      });
    },
  },
};
</script>

<style lang="scss">
@import '~vue-multi-select/dist/lib/vue-multi-select.css';

body {
  font-size: 12px;
}

#logo {
  display: none;
}

#internal {
  padding: 0;
}

#throttling-alert {
  background-color: #f44336;
  color: white;
  font-size: 12px;
  font-weight: bold;
  padding: 6px;
  text-align: center;
}

.my-4 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.alert {
  margin-bottom: 0;
  margin-top: 12px;
  padding: 1px;
}

.open-as-popup {
  margin-bottom: 6px;
  margin-top: 6px;
  a {
    font-weight: normal;
    .material-icons {
      font-size: 12px;
      vertical-align: -2px;
    }
  }
}

.call-count-badge {
  background-color: #757575;
  color: white;
  font-size: 9px;
  margin-right: 2px;
  padding-top: 3px;
  vertical-align: text-top;
}

.language-icon {
  height: 14px;
  margin-right: 2px;
  width: 14px;
}

.skill-icon {
  height: 14px;
  vertical-align: text-bottom;
  width: 14px;
}

// Used for DialedIn Agents.
.table-secondary {
  background-color: #e0e0e0;
}

.material-icons {
  color: #757575;
  font-size: 14px;
  vertical-align: text-bottom;
}

#status-table, #request-table {
  margin-top: 12px;
}

.table th, .table td {
  padding: 1px 6px;
}

.columns-dropdown {
  height: 24px;
  min-height: 24px !important;
  .material-icons {
    font-size: 14px;
    vertical-align: -2px;
  }
  .caret {
    margin-left: 6px !important;
  }
}

.columns-popover {
  .helperContainer {
    display: none;
  }
  .checkBoxContainer {
    padding: 3px !important;
    .selectItem {
      font-size: 12px !important;
      min-height: 24px !important;
      min-width: 200px !important;
    }
  }
}

#agent-count {
  line-height: 24px;
}

#filter-input {
  font-size: 12px !important;
  height: 24px !important;
  margin-left: 6px;
  width: 120px;
}

// https://github.com/Akryum/v-tooltip#style-examples
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>
